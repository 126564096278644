<template>
    <div id="content">
        <Blog v-if="selected_tab=='1'"/>
        <Works v-if="selected_tab=='2'"/>
        <Experience v-if="selected_tab=='3'"/>
        <About v-if="selected_tab=='4'"/>
        <Contact v-if="selected_tab=='5'"/>
    </div>
</template>

<script>
import Works from './Works.vue'
import Experience from './Experience.vue'
import About from './About.vue'
import Contact from './Contact.vue'
import Blog from './Blog.vue'

export default {
    name: 'Content',
    components: {
        Blog,
        Works,
        Experience,
        About,
        Contact
    },
    props: ["selected_tab"]
}
</script>

<style>
#content{
    display: inline-block;
    padding-top: 152px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    width: 82%;
}

@media screen and (max-width: 600px){
    #content{
        padding-top: 20px;
        width: 100%;
        text-align: center;
    }
    .heading{
        width: 100%;
        text-align: center;
    }
}

.heading{
    font-size: 28px;
}
.section{
    font-size: 24px;
}
.para{
    font-size: 16px;
}
</style>