<template>
  <div id="app">

    <Menu v-on:tab_selected="onTabSelected"/>

    <Content :selected_tab="current_tab_selection"/>
    
  </div>
</template>

<script>

import Menu from './components/Menu.vue'
import Content from './components/Content.vue'

export default {
  name: 'app',
  components: {
    Menu,
    Content
  },
  data: function(){
    return {
      current_tab_selection : '1'
    }
  },
  methods: {
    onTabSelected: function(tabVal){
      this.current_tab_selection = tabVal;
    }
  },
  mounted: function(){
    this.current_tab_selection = '2';
  }
}
</script>

<style>
#app {
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555555;
  height: inherit;
}

* {
  box-sizing: border-box;
}

/* Style the body */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    margin: 0;
    padding: 0;
}

ul, ol {
    list-style: none;
}
p{
  font-size: 16px;
}
</style>
