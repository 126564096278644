<template>
    <div id="blog">
        <div class="heading">
        Blog
        </div>
        <br/>
        <div id="medium-widget"></div>
        <script2 src="https://medium-widget.pixelpoint.io/widget.js"></script2>
        <script2>MediumWidget.Init({renderTo: '#medium-widget', params: {"resource":"https://medium.com/@bhuvanesh_shan","postsPerLine":1,"limit":10,"picture":"small","fields":["description"],"ratio":"landscape"}})</script2>
        <p>and more at 
        <a href="https://medium.com/@bhuvanesh_shan" target="_blank" rel="noopener noreferrer" class="hyperlink">
            <img height="22" width="22" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/medium.svg" style="vertical-align: bottom"/>
            Medium
        </a>
        </p>
    </div>
</template>

<script>
export default {
    name:'Blog'
}
</script>

<style scoped>
.hyperlink{
    padding-left: 8px;
    text-decoration: none;
    color: black;
}
</style>