<template>
    <div id="works">
        <div class="heading">
        Works
        </div>
        <div v-for="work of this.works" v-bind:key="work.name">
            <br/>
            <div>
                <img class="round" v-bind:src="work.image" width="100px">
                <div class="work-item">
                    <div style="font-size:24px">{{work.name}}</div>
                    <div style="font-size:16px">{{work.desc}}</div>
                    <div v-if="work.info" style="font-size:16px">{{work.info}}</div>
                    <div v-if="work.lang" style="font-size:14px">{{work.lang}} | <a v-bind:href="work.link" target="_blank" rel="noopener noreferrer">{{work.link}}</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Works',
    data: function(){
        return {
            works : [
                {
                    name : "Nira",
                    desc: "A web app to re-imagine your vector images. Color your SVG (Scalable Vector Graphics) files and icons easily.",
                    image: require('@/assets/8_nira.png'),
                    link: "https://nira-app.com",
                    lang: "Vue | Javascript",
                    info: "Implememnted auto generation of different kinds of color palettes to quickly apply and check the look of SVG files. Created a system that can parse and apply colors to elements inspite of the svg file containing css styles."
                },
                {
                    name : "Bezie",
                    desc: "A web app to create vectors images quickly.",
                    image: require('@/assets/6_bezie.png'),
                    link: "https://bezie-app.com",
                    lang: "Vue | Typescript",
                    info: "Created an internal system that can store properties of different types of shapes and vectors along with their animatable attributes for interpolation and easing during runtime. This is as a single page application using Vue and typescript and utilizes web canvas for WYSIWYG editor. Implemented ways to export user's creation in jpeg, png and gif formats."
                },
                {
                    name : "Mafia Companion - The Party Game Assistant",
                    desc: "An android app which assists in playing Mafia party game with friends in person",
                    image: require('@/assets/7_mafia_icon.png'),
                    link: "https://play.google.com/store/apps/details?id=com.bshan.daynight",
                    lang: "Android | Kotlin",
                    info: "This is a companion android app to play the famous party game - Mafia with friends in person. Helps with assinging roles for players and detailed rules."
                },
                {
                    name : "PolygonArt",
                    desc: "An android app to create low poly art using polygons as brushes. 10,000+ downloads!",
                    image: require('@/assets/1_polygon_art.png'),
                    link: "https://www.appbrain.com/app/polygon-art/bhuva.polygonart", //"https://play.google.com/store/apps/details?id=bhuva.polygonart"
                    lang: "Android | Java",
                    info: "Developed a grid based approach to find nearest set of vertices for a given point. Polygons were generated as a chain based on the preceding vertices."
                },
                {
                    name : "Easy pay",
                    desc: "An android experiment to simplify the process of mobile payment without taking smartphone out of one's pockets",
                    image: require('@/assets/4_easy_pay.png'),
                    link: "https://github.com/BhuvaneshShan/FinAppsParty",
                    lang: "Android | Java",
                    info: "Uses volume buttons and GPS to validate and proceed with payment"
                },
                {
                    name : "Ghosts",
                    desc: "A pac-man like simulation where the user needs to code the AI for pac-man",
                    image: require('@/assets/2_pac_man.png'),
                    link: "https://github.com/BhuvaneshShan/Ghosts",
                    lang: "Processing",
                    info: "Created as a final level puzzle challenge for participants of a coding competition in undergrad."
                },
                {
                    name : "Lost in the Lost Temple",
                    desc: "A desktop adventure game with multiple levels and a level editor",
                    image: require('@/assets/3_lilt.png'),
                    link: "https://github.com/BhuvaneshShan/LostInTheLostTemple",
                    lang: "C++ | SDL",
                    info: "Designed and develped from scratch using Simple Directmedia Layer (SDL) library in C++. To quickly design levels, created a level editor that aids with placedment of objects and level visualization."
                },
                {
                    name : "Misc Games",
                    desc: "Games such as snakes, Chutes and Ladders that I created during my undergrad days",
                    image: require('@/assets/5_misc_games.png'),
                    lang: "C++ | SDL"
                },
            ]
        }
    }
}
</script>

<style>
.left{
    display: inline-block;
}
.round{
     border-radius: 50%;
     float: left;
}
.work-item{
    /*display:inline-block; 
    vertical-align:top; 
    margin-top:20px; 
    */
    margin-left:120px
}

@media screen and (max-width: 600px){
    .work-item{
        display: inline-block;
        margin-left:0px
    }
    .round{
        border-radius: 50%;
        display:inline-block;
        float: none;
    }
}
</style>