<template>
    <div id="contact">
        <div class="heading">
        Contact
        </div>
        <br/>
        <p>You can reach me at <b> bhuvanesh . bleu @ gmail.com </b></p>
        <br/>
        <p>
        I'm interested in talking about anything involving building products, design, technology and economy. 
        </p>
        <p>
        I'm also open feedback regarding the apps I've created. 
        </p>
        <p>
        May be I'll find a good feature to add to one my apps based on our conversation.
        </p>
        
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>

<style>

</style>