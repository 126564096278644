<template>
    <div id="about" class="text">
        <div class="heading">
            About
        </div>
        <br/>
        <p>
            Hey there, glad to know that you are curious about me. You can call me Bhuvanesh [ bu . van . esh ] or BShan</p>
            <p>I was born in Erode, India and I currently live in Atlanta.
            A creator by heart, I love to see things come to life.
        </p>
        <br/>
        <p>
            I work as a senior software engineer at Google on Google Home App. Prior to that I was at VMware and EA Games. My expertise is in android, mobile and creative applications, software architecture and engineering.</p>
            <p>I did my masters in computer science at Georgia Tech and my undergrad was at College of Engineering, Guindy.
        </p>
        <br/>
        <p>
            During my leisure, I play tennis or board games. Big Age of Empires II fan.</p>
            <p>I love art but it's been a while since I touched the brushes. Did some graphic design during my college days!</p>
            <p>Nowadays, I spend my time developing apps that enable people to be creative.
        </p>
        <br/>
        <p>
            The following lines by Robert Frost stuck with me ever since I encountered them.
        </p>
        <div style="padding:24px;font-style:italic">
            The woods are lovely, dark and deep,
            <p>But I have promises to keep,</p>
            <p>And miles to go before I sleep,</p>
            <p>And miles to go before I sleep.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style scoped>
.text{
    font-size: 16px;
}
</style>