<template>
    <div class="exp">
        <div class="heading">
            Experience
        </div>

        <div v-for="exp of this.getExps()" v-bind:key="exp.startMonth">
            <br/>
            <div style="font-size:14px">
                {{exp.startMonth}}
            </div>
            <div style="padding-left:20px">
                <div class="section">
                    {{exp.position}}
                </div>
                <div style="font-size:14px">
                    {{exp.org}}, {{exp.location}}
                </div>
                <div style="font-size:14px">
                    {{exp.desc}}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Experience',
    data: function(){
        return {
            exps: [
                {
                    startMonth: "Feb 2022 - Present",
                    position: "Senior Software Engineer",
                    org: "Google",
                    location: "Atlanta, USA",
                    desc: "Working on Google Home App for Android devices. I take care of release engineering, settings and enrollment frameworks for smart home devices."
                },
                {
                    startMonth: "Jan 2017 - Feb 2022",
                    position: "Member of Technical Staff",
                    org: "VMware",
                    location: "Atlanta, USA",
                    desc: "Worked in the Android Hub team as a software developer. Involved in desinging and developing features for device management on android for enterprises around the world."
                },
                {
                    startMonth: "May 2016",
                    position: "Intern - Software Developer",
                    org: "VMware",
                    location: "Atlanta, USA",
                    desc: "Worked on implementing Single sign on flow and secured app-to-app communication for Workspace One in Windows platform."
                },
                {
                    startMonth: "Jan 2016",
                    position: "Graduate Research Assistant",
                    org: "Georgia Tech",
                    location: "Atlanta, USA",
                    desc: "Developed scripts to streamline the visualization process of generating PDFs and images from existing environmental data. Experimented with classification algorithms and helped migrate existing environmental data into new model for further processing."
                },
                {
                    startMonth: "June 2014",
                    position: "Software Developer 1",
                    org: "Electronic Arts",
                    location: "Hyderabad, India",
                    desc: "Developed software tools for mobile platforms such as Android and Windows applications to speed up processes in the game development pipeline."
                },
                {
                    startMonth: "May 2013",
                    position: "Intern - Software Developer",
                    org: "Electronic Arts",
                    location: "Hyderabad, India",
                    desc: "Implemented a universal way to adapting game ui based on screen orientation and sizes."
                },
                {
                    startMonth: "May 2012",
                    position: "JENESYS Program",
                    org: "Japanese Embassy",
                    location: "Tokyo, Japan",
                    desc: "Took part in an exchange program to Japan sponsored by the Embassy of Japan."
                }
            ]
        }
    },
    methods:{
        getExps: function(){
            return this.exps;
        }
    }
}
</script>

<style>

</style>