<template>
    <div id="menu">
    <img class="avatar" src="@/assets/myPic3.jpeg" width="100px"/>
    <div class="name" @click="selectTab(2)">
        <span>
            <b style="font-weight:bold">B</b>huvanesh
        </span>
            <div/>
        <span>    
            <b style="font-weight:bold">Shan</b>muga
        </span>
            
        <span>
            Sundaram
        </span>
    </div>

    <div class="menu-items">
        <ul>
            <!--<li @click="selectTab(1)">Blog</li>-->
            <li @click="selectTab(2)">Works</li>
            <li @click="selectTab(3)">Experience</li>
            <li><a href="https://drive.google.com/file/d/1lE8_jEpIw3xfZTWPiwFF_xCEmwjZgOjO/view?usp=sharing" target="_blank" rel="noopener noreferrer" style="text-decoration:none; color:#555555">Resume</a></li>
            <li @click="selectTab(4)">About</li>
            <li @click="selectTab(5)">Contact</li>
        </ul>
    </div>
    <br/>
    <div class="icons">
        <a href="https://github.com/BhuvaneshShan" target="_blank" rel="noopener noreferrer">
            <img height="16" width="16" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/github.svg" />
        </a>
        <a href="https://twitter.com/Bhuvanesh_Shan" target="_blank" rel="noopener noreferrer">
            <img height="16" width="16" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/twitter.svg" />
        </a>
        <a href="https://www.linkedin.com/in/bhuvaneshshan/" target="_blank" rel="noopener noreferrer">
            <img height="16" width="16" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/linkedin.svg" />
        </a>
        <a href="https://medium.com/@bhuvanesh_shan" target="_blank" rel="noopener noreferrer">
            <img height="16" width="16" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/medium.svg" />
        </a>
    </div>

    </div>

</template>

<script>
export default {
  name: 'Menu',
  methods:{
      selectTab: function(tabVal){
          this.$emit('tab_selected',tabVal);
      }
  }
}
</script>

<style scoped>
#menu{
    width: 18%;
    padding-top: 120px;
    padding-bottom: 100px;
    padding-right: 20px;
    padding-left: 20px;
    height: inherit;
    text-align: right;
    float: left;
    font-size: 14px;
}

.menu-items{
    margin-top:40px
}

@media screen and (max-width: 600px){
    #menu {
        float: none;
        padding: 0px;
        height: min-content;
        text-align: center;
        width: 100%;
    }
    .avatar{
        margin-top: 10px;
        margin-bottom: 5px; 
    }
    .menu-items{
        margin-top: 20px;
    }
}

.name{
    font-size: 28px;
    font-weight: lighter;
    cursor: pointer;
}

li{
    margin-top:10px;  
    cursor: pointer;  
    font-size: 16px;
}

.avatar{
    border-radius: 50%; 
    margin-bottom: 20px
}

img{
    margin-left: 10px;
}

.icons{
    margin-top: 20px;
}
</style>